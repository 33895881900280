import * as React from "react";

import AccountForm from "../components/Account/AccountForm";
import AuthContent from '../components/Login/AuthContent';
import Layout from "../components/Base/Layout";
import { SEO } from "../components/Base/Seo";

const AccSettings = () => {
  return (
    <Layout>
      <AuthContent>
        <AccountForm />
      </AuthContent>
    </Layout>
  );
}

export default AccSettings

export const Head = () => (
  <SEO title="Global Care Supply | Account Settings" description="Your account settings." />
)
