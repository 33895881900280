import * as React from 'react'

import { Box, Button, Container, Link, Stack, StackDivider, Text } from '@chakra-ui/react'
import { gql, useMutation } from '@apollo/client';
// import { URLSearchParams } from "url"
import useAuth, { User }  from '../useAuth';

import LogOut from './LogOut';
import PasswordCard from './PasswordCard'
import PersonalInfoCard from './PersonalInfoCard'

const AccountForm = () => {
    const params = new URLSearchParams(window.location.search);
    console.log(params)
    const resetKey = params.get('keys') || '';
    const login = params.get('login') || '';

    return (
    <Container bg="gray.50" maxW="100%" py={{ base: '16', md: '16' }}>
        <Stack spacing="5" divider={<StackDivider />}>
            <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: '5', lg: '8' }} justify="space-between" p={{sm: '32px', base: "0px"}}>
                <Box flexShrink={0}>
                    <Text fontSize="lg" fontWeight="medium">
                        Personal Information
                    </Text>
                    <Text color="muted" fontSize="sm">
                        Your name and address for billing and shipping.
                    </Text>
                </Box>
                <PersonalInfoCard maxW={{ lg: '680px' }} />
            </Stack>

            <Stack direction={{ base: 'column', lg: 'row' }}  spacing={{ base: '5', lg: '8' }} justify="space-between" p={{sm: '32px', base: "0px"}}>
                <Box flexShrink={0}>
                    <Text fontSize="lg" fontWeight="medium">
                        Password
                    </Text>
                    <Text color="muted" fontSize="sm">
                        Change your password
                    </Text>
                </Box>
                <PasswordCard ></PasswordCard>
                </Stack>
               
            </Stack>
            
            {/* LOGOUT */}
            <LogOut></LogOut>
    </Container>
    )
}

export default AccountForm